module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"backgroundColor":"none","maxWidth":900,"linkImagesToOriginal":false,"disableBgImage":true,"wrapperStyle":"margin: 1.5rem 0;"}},{"resolve":"gatsby-remark-double-brackets-link","options":{"titleToURLPath":"/opt/build/repo/gatsby-theme-code-notes/src/utils/resolve-url.js","stripBrackets":true}}],"remarkPlugins":[null,null,null,[null,{"style":"smart"}],null],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo/example"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-code-notes/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"code-notes","basePath":"/","gitRepoContentPath":"https://github.com/Ad0lphus/notes/tree/master/example/code-notes/","showDescriptionInSidebar":true,"showThemeInfo":true,"logo":"https://github.com/Ad0lphus/old-landing-page/blob/main/app/assets/work/logo.png?raw=true","openSearch":{"siteShortName":"writeups","siteUrl":"https://notes.prabithgupta.com/","siteTags":"front-end","siteContact":"https://twitter.com/Ad0lphu5","siteDescription":"place where i publish my notes and writeups"},"showDate":true},
    }]
